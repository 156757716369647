export const lang = {
  ENGLISH: 'en',
};

export const SMALL_NAV_SIZE = '80px';
export const LARGE_NAV_SIZE = '236px';

export const PASSWORD_STRENGTH_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&.*])(?=.{8,})/;
export const PHONE_NO_REGEX =
  /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;

// DATE FNS FORMAT
export const MONTH_DAY_YEAR_FORMAT = 'P'; // 04/29/1453
export const MONTH_DAY_YEAR_DASH_FORMAT = 'yyyy-MM-dd';
export const MONTH_DAY_TIME_FORMAT = 'MM/dd, hh:mm a';
export const MONTH_DAY_YEAR_TIME_FORMAT = 'MM/dd/yyyy, hh:mm a';
export const MONTH_DAY_YEAR_TIME_POST_FORMAT = 'MM/dd/yyyy hh:mm:ss a';
export const YEAR_MONTH_DAY_FORMAT = 'yyyy/MM/dd';
export const HOUR_MINUTE_12_HOUR_FORMAT = 'hh:mm a'; // 12:00 AM
export const LONG_LOCALIZED_DATE = 'PP'; // Apr 29, 1453

export const UTC_STRING_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ssxxx";
export const UTC_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";

export const INFINITE_SCROLL_PAGE_SIZE = 50;

export const REDIRECT_FROM_LOGIN = 'REDIRECT_FROM_LOGIN';
export const FREETRIAL = 'freeTrial';

export const MILES_IN_KILOMETERS = 1.609344;

export const MILLISECONDS_DAY_FACTOR = 86400000;

export const EDIT_LOG_CODE = {
  6: 'Entry Updated',
  32: 'Break Added',
  33: 'Break Updated',
  34: 'Break Deleted',
};

export const AUTO_CLOCK_IN_COMPANY_IDS = import.meta.env.VITE_AUTO_CLOCK_IN_IDS
  ? import.meta.env.VITE_AUTO_CLOCK_IN_IDS.split(',').map((id: string) => Number(id))
  : [
      7545, 5584, 5343, 5830, 7468, 7903, 7322, 7102, 4690, 7102, 7107, 7773, 7219, 5953, 5635, 6553, 5095, 6142, 9464,
      12004, 12013, 10540, 12390, 12465, 12547, 4640, 12593,
    ];

export const ADP_MARKETPLACE_URL = 'https://apps.adp.com';
